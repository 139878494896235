<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Kelas Jabatan</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="9" lg="9" md="9" sm="12" xs="12" class="px-0">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  visible = true;
                  form.kelas_jabatan = null;
                "
                >Buat Kelas Jabatan<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="lists"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.nilai_tunjangan`]="{ item }">
              <span>Rp. {{ item.nilai_tunjangan | currency }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="primary" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="650">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Kelas Jabatan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nilai Bawah</v-subheader
              >
            </v-col>
            <v-col cols="2" class="py-1">
              <v-text-field
                v-model="form.nilai_bawah"
                outlined
                dense
                :rules="[v => !!v || 'Nilai Bawah harus diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nilai Atas</v-subheader
              >
            </v-col>
            <v-col cols="2" class="py-1">
              <v-text-field
                v-model="form.nilai_atas"
                outlined
                dense
                :rules="[v => !!v || 'Nilai Atas harus diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nilai</v-subheader
              >
            </v-col>
            <v-col cols="2" class="py-1">
              <v-text-field
                v-model="form.nilai_jabatan"
                outlined
                dense
                :rules="[v => !!v || 'Nilai harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tunjangan Kinerja</v-subheader
              >
            </v-col>
            <v-col cols="10" class="py-1">
              <v-text-field
                v-model="form.nilai_tunjangan"
                outlined
                dense
                :rules="[v => !!v || 'Tunjangan Kinerja harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Keterangan</v-subheader
              >
            </v-col>
            <v-col cols="10" class="py-1">
              <v-text-field
                v-model="form.keterangan"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      formLoading: false,
      levels: [1, 2, 3, 4],
      search: null,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "Nilai Bawah",
          value: "nilai_bawah",
          sortable: false,
          align: "center"
        },
        {
          text: "Nilai Atas",
          value: "nilai_atas",
          sortable: false,
          align: "center"
        },
        {
          text: "Nilai Jabatan",
          value: "nilai_jabatan",
          sortable: false,
          align: "center"
        },
        {
          text: "Tunjangan Kinerja",
          value: "nilai_tunjangan",
          sortable: false,
          align: "center"
        },
        {
          text: "Keterangan",
          value: "keterangan",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      lists: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["kelas_jabatan"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        kelas_jabatan: null,
        nilai_bawah: null,
        nilai_atas: null,
        nilai_jabatan: null,
        nilai_tunjangan: null,
        keterangan: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getKelasJabatanList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getKelasJabatanList);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.year, this.unit_kerja_2_id].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.getDetailKelasJabatan(item.kelas_jabatan);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              kelas_jabatan: item.kelas_jabatan
            };
            this.deleteKelasJabatan(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.form.kelas_jabatan ? 2 : 1);
        formData.append("kelas_jabatan", this.form.kelas_jabatan);
        formData.append("nilai_bawah", this.form.nilai_bawah);
        formData.append("nilai_atas", this.form.nilai_atas);
        formData.append("nilai_jabatan", this.form.nilai_jabatan);
        formData.append("nilai_tunjangan", this.form.nilai_tunjangan);
        formData.append("keterangan", this.form.keterangan);
        this.saveKelasJabatan(formData);
      }
    },
    // Service
    async getKelasJabatanList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getKelasJabatanList({
          filter: {
            search: this.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            let { list, pageTotal, countTotal } = data;
            if (status) {
              let lists = list;
              lists.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.lists = lists;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveKelasJabatan(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveKelasJabatan(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getKelasJabatanList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailKelasJabatan(id) {
      try {
        this.loading = true;
        await DataUtamaService.getKelasJabatanDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              (this.form.kelas_jabatan = data.kelas_jabatan),
                (this.form.nilai_bawah = data.nilai_bawah),
                (this.form.nilai_atas = data.nilai_atas),
                (this.form.nilai_jabatan = data.nilai_jabatan),
                (this.form.nilai_tunjangan = data.nilai_tunjangan),
                (this.form.keterangan = data.keterangan);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteKelasJabatan(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteKelasJabatan(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getKelasJabatanList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
